import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";
import posthog from "posthog-js"

// studio.extend(extension);
// studio.initialize();


// import studio from '@theatre/studio'
// import extension from '@theatre/r3f/dist/extension'

const root = ReactDOM.createRoot(document.getElementById('root'));

if (!window.location.host.includes("127.0.0.1") && !window.location.host.includes("localhost")) {
  posthog.init(
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
    { api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST, person_profiles: "identified_only" })
}

root.render(
  <React.StrictMode>
      <Suspense fallback={<LoadingScreen/>}>
          <App/>
      </Suspense>
  </React.StrictMode>
);

