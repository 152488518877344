import {Html, Scroll, ScrollControls} from "@react-three/drei";
import {SheetProvider} from "@theatre/r3f";
import StaticPages from "./StaticPages/StaticPages";
import {Canvas} from "@react-three/fiber";
import React, {lazy, useCallback, useEffect, useState} from "react";
import {getProject} from "@theatre/core";
import animation from "../../assets/animations/fly-through-animation.json";
const Scene = lazy(()=> import("./3D/Scene"));

function HomePage() {
    const [sheet, setSheet] = useState(null);
    const [scrollOffset, setScrollOffset] = useState(0);
    const animationPages = 10;
    const [dimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateScrollOffset = useCallback((width) => {
        let featuresHeight =
            width >= 1440 ? 689 :
                width >= 861 ? 1212 :
                    width >= 621 ? 1144 :
                        2190;
        let solutionsHeight =
            width >= 1440 ? 874 :
                width >= 861 ? 940 :
                    width >= 710 ? 872 :
                        width >= 621 ? 1160 :
                            1912;
        let communityHeight =
            width >= 1440 ? 1154 :
                width >= 1200 ? 950 :
                    width >= 861 ? 1100 :
                        width >= 621 ? 1010 :
                            width >= 489 ? 912 :
                                width >= 410 ? 722 :
                                    770;
        let footerHeight =
            width >= 1440 ? 151 :
                width >= 887 ? 272 :
                    width >= 428 ? 429 :
                        557;
        const newScrollOffset = featuresHeight + solutionsHeight + communityHeight + footerHeight;
        setScrollOffset(newScrollOffset);
    }, []);

    const handleResize = useCallback(() => {
        if (window.innerWidth !== dimensions.width || Math.abs(dimensions.height - window.innerHeight) >= window.innerHeight) {
            window.location.reload();
        }
        dimensions.height = window.innerHeight;
    }, [dimensions]);

    useEffect(() => {
        const project = getProject('Streamiverse', {state: animation});
        const sheet = project.sheet('Scene');
        setSheet(sheet);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        updateScrollOffset(dimensions.width);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dimensions.width, handleResize, updateScrollOffset]);

    return (
        <div className={'h-full background-neutral-900'}>
            <Canvas id={'canvas'} gl={{preserveDrawingBuffer: true}}
                    key={window.innerWidth + window.innerHeight}
            >
                <ScrollControls
                    pages={dimensions.width >= 690 ? animationPages + scrollOffset / dimensions.height : 0.5 * (animationPages + scrollOffset / dimensions.height)}>
                    <Scroll>
                        <SheetProvider sheet={sheet}>
                            <Scene/>
                            <Html className={'canvas-html'}>
                                <StaticPages scrollOffset={scrollOffset} animationPages={animationPages} dimensions={dimensions}/>
                            </Html>
                        </SheetProvider>
                    </Scroll>
                </ScrollControls>
            </Canvas>
        </div>
    )
}

export default HomePage;
