import './LegalPages.css'
function TermsOfUse() {
    return (
        <div className={'doc'}>
            <div className={'doc-wrapper'}>
                <h1 className={'doc-header'}>
                    Terms of Use
                </h1>
                <h3 className={'doc-text last-amended'}>
                    Last amended on 07-25-2024
                </h3>
                <h2 className={'doc-subheader'} id={'doc-subheader--first'}>
                    Preamble
                </h2>
                <p className={'doc-text border-primary'}>
                    This Terms of Use (hereinafter – the Terms) and other
                    documents that are incorporated herein or referred to herein set forth the terms and condition on
                    which
                    you, the Streamer, are granted
                    by us, the Service Provider, with an access and permission to use
                    the Streamiverse Technology and Services. To use the Platform you
                    have to accept the Terms fully. There is no possibility to
                    accept the Terms partially.
                </p>
                <p className={'doc-text'}>
                    We reserve the right to amend these Terms at our discretion at any time. In this case you are
                    considered to agree to amended Terms by continuing the use of the Streamiverse Technology
                    and Services.
                </p>
                <br/>
                <p className={'doc-text'}>
                    The Service Provider you are contracting with is:
                </p>
                <br/>
                <p className={'doc-text'}>
                    Streamiverse LLS, incorporated in the Saint Vincent and the Grenadines and
                    operating under the laws of Saint Vincent and the Grenadines.
                </p>
                <br/>
                <p className={'doc-text'}>
                    Euro House, Richmond Hil Road, Kingstown, St. Vincent and the Grenadines
                </p>
                <br/>
                <p className={'doc-text'}>
                    The Streamiverse Technology and Services is a one-stop complex of
                    software, administrative, marketing and IT solutions of different kinds that help the Streamer to
                    manage
                    donating and merch campaigns and monetizing their community.
                </p>


                <p className={'doc-subheader'}>
                    Intended Use
                </p>
                <p className={'doc-text'}>
                    The Streamiverse Technology and Services shall only be used to collect, manage and operate
                    donations, manage and operate merch campaigns. The Streamiverse Technology and Services
                    shall not be used as a means to accept payments for the services or goods or for other
                    commercial use.
                </p>
                <p className={'doc-subheader'}>
                    Legal Requirements to the Streamer
                </p>
                <p className={'doc-subheader-2'}>
                    No Misleading Information
                </p>
                <p className={'doc-text'}>
                    In order to use the Streamiverse Technology and Services the Streamer has to create an account
                    and provide certain personal information. The Streamer has to provide real, accurate
                    information and obliges to keep information current at any time as well as provide personal
                    information upon Service Provider’s request.
                </p>
                <p className={'doc-subheader-2'}>
                    Legal Age and Capacity
                </p>
                <p className={'doc-text'}>
                    In order to use the Streamiverse Technology and Services the Streamer has to be either the
                    legal organization or the individual of a full legal age in accordance with the place of its
                    citizenship and/or residency, whichever is set up higher, and with a full capacity to act as a
                    Streamer.
                </p>
                <p className={'doc-subheader-2'}>
                    Authorized Representation Is Required
                </p>
                <p className={'doc-text'}>
                    To use the Streamiverse Technology and Services on behalf of an organization an authorized
                    representative of that organization must agree to these terms.
                </p>
                <p className={'doc-subheader-2'}>
                    Residency and Jurisdiction Restrictions
                </p>
                <p className={'doc-text'}>
                    The Streamer has to be incorporated and/or be a resident of the country where the
                    Streamiverse Technology and Services are provided without limitations. For avoidance of any
                    doubt the Streamiverse Technology and Services are not provided and the Service Provider does
                    not operate in the following jurisdictions:
                </p>
                <ul className={'doc-text'}>
                    <li>Any jurisdiction that require a license or any other permit to provide or use the Streamiverse
                        Technology and Services that the Service Provider or the Streamer had not obtained;
                    </li>
                    <li>Crimea and Sevastopol;</li>
                    <li>Cuba;</li>
                    <li>North Korea;</li>
                    <li>Sudan;</li>
                    <li>Syria;</li>
                    <li>the United States of America;</li>
                    <li>any state, country or other jurisdiction that is embargoed by the United States of America;</li>
                    <li>other territories where the Service Provider may at any time cease or suspend their
                        operations.
                    </li>
                </ul>
                <p className={'doc-subheader-2'}>
                    PEPs Are Not Allowed
                </p>
                <p className={'doc-text'}>
                    The Streamer guarantees and confirms that at any time of the use of the Streamiverse
                    Technology and Services the Streamer is not a Politically Exposed Person (PEP), a family
                    member or close associate of the Politically Exposed Person as defined by FATF
                    Recommendations and Guidance.
                </p>
                <p className={'doc-subheader-2'}>
                    No Representation. Fake Accounts and Impersonation Prohibited
                </p>
                <p className={'doc-text'}>
                    The Streamer may only use the Streamiverse Technology and Services with their own e-mail and
                    for their own benefit and shall not act on behalf and/or to the interest of any other person or
                    entity other than authorized representation of a legal organization. Similarly it is prohibited to
                    create fake accounts, impersonate anyone else and mislead others into thinking that the
                    donating or merch campaigns originate from other persons or entities.
                </p>
                <p className={'doc-subheader-2'}>
                    No Illegal Goals and Fraudulent Actions
                </p>
                <p className={'doc-text'}>
                    The Streamer may only use the Streamiverse Technology and Services to achieve legal and
                    lawful goals. In no case the Streamiverse Technology and Services may be used in fraudulent or
                    deceptive ways and/or to violate any law, contract, others rights, mislead or engage others into
                    illegal activity.
                </p>
                <p className={'doc-subheader-2'}>
                    Additional Requirements
                </p>
                <p className={'doc-text'}>
                    Additional, auxiliary or occasional requirements may be applied to all or any part of the
                    Streamiverse Technology and Services at any time. Such requirements shall be deemed as an
                    integral part of these Terms.
                </p>


                <p className={'doc-subheader'}>
                    Indemnification and Limitations of Liability
                </p>
                <p className={'doc-subheader-2'}>
                    Use At Your Own Risk
                </p>
                <p className={'doc-text'}>
                    The Streamer acknowledges and agrees that their access and use of the Streamiverse
                    Technology and Services is at their own risk. THE STREAMER FURTHER AGREES TO INDEMNIFY
                    AND HOLD THE SERVICE PROVIDER HARMLESS AGAINST ANY CLAIMS, DEMANDS AND
                    DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER
                    DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR
                    LOSS OF DATA, WHETHER IN AN ACTION, IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
                    NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH THEIR
                    USE OF THE STREAMIVERSE TECHNOLOGY AND SERVICES, INCLUDING, BUT NOT LIMITED TO
                    THOSE ARISING FROM THEIR PERSONAL ERROR AND MISBEHAVIOR SUCH AS INCORRECTLY
                    APPLIED SETTINGS AND USED IN-STREAM TOOLS, INDICATED ADDRESSES AND ACCOUNTS FOR
                    TRANSACTIONS, LOSS OF ACCESSES TO THE STREAMIVERSE TECHNOLOGY AND SERVICES OR
                    EMAIL, OR OTHER, FORGOTTEN OR LOST PASSWORDS, BACKUP MNEMONICS, PRIVATE KEYS
                    ETC.
                </p>
                <p className={'doc-subheader-2'}>
                    No Warranties. “As Is” Basis
                </p>
                <p className={'doc-text'}>
                    THE STREAMIVERSE TECHNOLOGY AND SERVICES PROVIDED ON AN "AS IS" AND "AS
                    AVAILABLE" BASIS. THE SERVICE PROVIDER EXPRESSLY DISCLAIMS, AND THE STREAMER WAIVES,
                    ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                    LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE STREAMIVERSE TECHNOLOGY AND
                    SERVICES, INCLUDING THE INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN.
                </p>
                <p className={'doc-subheader-2'}>
                    No Liability On Third Parties’ Solutions
                </p>
                <p className={'doc-text'}>
                    Some parts of the Streamiverse Technology and Services such as fiat and digital assets
                    payments, wallet features and others are provided via third parties not affiliated with the
                    Service Provider. The Service Provider is not a part of your relationships with such third parties
                    and disclaims any liability for any defaults on their behalf.
                </p>
                <p className={'doc-subheader-2'}>
                    Litigation Indemnification
                </p>
                <p className={'doc-text'}>
                    TO THE EXTENT ALLOWED BY APPLICABLE LAW, THE STREAMER INDEMNIFIES SERVICE
                    PROVIDER AND ITS DIRECTORS, OFFICERS, EMPLOYEES, AND CONTRACTORS FOR ANY
                    THIRD-PARTY LEGAL PROCEEDINGS (INCLUDING ACTIONS BY GOVERNMENT AUTHORITIES)
                    ARISING OUT OF OR RELATING TO THE STREAMER’S UNLAWFUL USE OF THE STREAMIVERSE
                    TECHNOLOGY AND SERVICES OR VIOLATION OF THE TERMS. THIS INDEMNITY COVERS ANY
                    LIABILITY OR EXPENSE ARISING FROM CLAIMS, LOSSES, DAMAGES, JUDGMENTS, FINES,
                    LITIGATION COSTS, AND LEGAL FEES.
                </p>
                <p className={'doc-subheader-2'}>
                    Limitation of Liabilities
                </p>
                <p className={'doc-text'}>
                    Service Provider won’t be responsible for loss of profits, revenues, business opportunities or
                    anticipated savings and earnings, goodwill, indirect or consequential loss and punitive damages.
                    Service Provider’s total, aggregate liability arising out of or relating to the Terms and
                    Streamiverse Technology and Services is limited to the amount of fees paid by the Streamer to
                    Service Provider to use Streamiverse Technology and Services in the 6 immediately preceding
                    month before the breach.
                </p>


                <p className={'doc-subheader'}>
                    Terms of the Streamiverse Technology and Services Provision
                </p>
                <p className={'doc-subheader-2'}>
                    Obligatory To Comply
                </p>
                <p className={'doc-text'}>
                    The permission the Service Provider gives to the Streamer to access and use its services
                    continues as long as the Streamer complies with these Terms and any other service specific
                    terms as may be introduced from time to time. At any time following Streamer’s
                    non-compliance with the Terms the Service Provider is entitled to cease, suspend or ban
                    provision of the Streamiverse Technology and Services to the Streamer.
                </p>
                <p className={'doc-subheader-2'}>
                    Software License
                </p>
                <p className={'doc-text'}>
                    The permission the Service Provider gives to Streamer to access and use its services includes
                    among others personal, non-exclusive, non-transferable, worldwide, royalty-free license to use
                    the software, including without limitation web-version of donating platform and APK files for its
                    intended use only. It is not allowed to copy, modify, distribute, sell, or lease any part of the
                    Streamiverse Technology and Services or software.
                </p>
                <p className={'doc-subheader-2'}>
                    Intellectual Property Retention
                </p>
                <p className={'doc-text'}>
                    Nothing in these Terms and any particular communication between the Streamer and Service
                    Provider constitutes any assignment or disposition of the Service Provider’s Intellectual
                    Property.
                </p>
                <p className={'doc-subheader-2'}>
                    Fees
                </p>
                <p className={'doc-text'}>
                    The Service Provider has the right to charge fees for the Streamiverse Technology and Services
                    in accordance with current rates as well as release from fees or any part of it at any time. All
                    due Fees may be directly withdrawn from the Streamer’s account.
                </p>
                <p className={'doc-subheader-2'}>
                    Blockchain And Third-Parties Fees
                </p>
                <p className={'doc-text'}>
                    Blockchain and fiat transactions as well as services and solutions provided by third parties may
                    be subject to additional fees charged by the network and or third party providers. The Service
                    Provider is not a party to these transactions, does not act as a broker or agent, has no control
                    over them and is not liable for any of them.
                </p>
                <p className={'doc-subheader-2'}>
                    Reverse Engineering Is Prohibited
                </p>
                <p className={'doc-text'}>
                    Reverse engineering of the Streamiverse Technology and Services or underlying technology is
                    strictly prohibited.
                </p>
                <p className={'doc-subheader-2'}>
                    Content Use
                </p>
                <p className={'doc-text'}>
                    Some of the Streamiverse Technology and Services may include content that belongs to the
                    Service Provider. You may use this content as allowed by these Terms, but Service Provider
                    retains any intellectual property rights thereto. Don’t remove, obscure, or alter any of the
                    Service Provider’s brandings, logos or legal notices.
                </p>
                <p className={'doc-subheader-2'}>
                    Consents
                </p>
                <p className={'doc-text'}>
                    To provide Streamiverse Technology and Services Service Provider requires to collect, manage
                    and operate certain information, such as for example information identifying the Streamer or
                    history of transactions, technically provide access to Streamiverse Technology and Services,
                    provide technical solution to collect and manage donations, integrate third parties solutions to
                    the Streamiverse Technology and Services and connect to Streamer’s account etc. The Streamer
                    gives its full non-revocable consent and permission to the Service Provider on all necessary
                    actions that have to be undertaken on their behalf, by creating an account and using the
                    Streamiverse Technology and Services.
                </p>


                <p className={'doc-subheader'}>
                    Suspension And Termination Of The Access To Streamiverse Technology And Services
                </p>
                <p className={'doc-subheader-2'}>
                    The Service Provider may suspend or terminate access
                </p>
                <p className={'doc-text'}>
                    The Service Provider may suspend or terminate the Streamer’s access to the Streamiverse
                    Technology and Services or ban and delete Streamer’s account in case of (i) material or
                    repeated breach of the Terms, (ii) necessity to comply with a legal requirement or order of a
                    competent authority, or (iii) if there is a reasonable belief that Streamer’s use of the
                    Streamiverse Technology and Services may cause harm to the Service Provider, other Streamer
                    or any third party.
                </p>
                <p className={'doc-subheader-2'}>
                    The Streamer may close their account
                </p>
                <p className={'doc-text'}>
                    The Streamer is entitled to close their account with no cause. Closing of the account and
                    discontinuation of the use of the Streamiverse Technology and Services does not relieve the
                    Streamer from any due payments.
                </p>
                <p className={'doc-subheader-2'}>
                    Account settlement. No refunds.
                </p>
                <p className={'doc-text'}>
                    Upon termination of the Streamer’s account or their access to the Streamiverse Technology and
                    Services all due payments shall be due for payment immediately and may be automatically
                    withdrawn from Streamer’s account. In case of prepaid services no refund is provided.
                </p>


                <p className={'doc-subheader'}>
                    Disputes Resolution
                </p>
                <p className={'doc-subheader-2'}>
                    Negotiations And Arbitration
                </p>
                <p className={'doc-text'}>
                    Any dispute arising out of or in connection with the Terms and /or the Streamiverse Technology
                    and Services shall first be negotiated. In case the amicable settlement is not achieved within 6
                    months, both the Streamer and the Service Provider agree that the dispute shall be finally
                    resolved in arbitration by Eastern Caribbean Supreme Court in accordance with its Civil
                    Procedure Rules.
                </p>
                <p className={'doc-subheader-2'}>
                    The Governing Law
                </p>
                <p className={'doc-text'}>
                    The Terms and rhe relationship between the Streamer and the Service Provider are governed by
                    the laws of Saint Vincent and Grenadines.
                </p>
                <p className={'doc-subheader-2'}>
                    Arbitration seat, language and arbitrators
                </p>
                <p className={'doc-text'}>
                    The seat of the arbitration shall be Saint Vincent and Grenadines. The number of arbitrators
                    shall be one. The language of the arbitration proceedings shall be English.
                </p>
            </div>
        </div>
    )
}

export default TermsOfUse;